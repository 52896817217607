.contact{
    position: absolute;
    left: -10px;
    top: 20px;
    z-index:-1;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    /* width: 100%; */
    background-color: #e1e2e2;
    border-radius: 5px;
    height:520px;
    text-align: center;
}
.form-label{
    color: white;
}

.contact-form{
    padding-top: 20px;
    width: 90%;
    margin: auto;
}
  