.resume{
    position: absolute;
    left: -10px;
    top: 20px;
    z-index:-1;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    /* width: 100%; */
}

.resume h3{
    margin: 0 0 0 20px;
}

.table{
    margin: 0 0 0 0px;
    text-align: center;
}
.table{
    background-color: #e1e2e2;
    color: #1c2b24;
    border: 1px solid #ffffff;
}

.table ul li{
    list-style-type: none;
}