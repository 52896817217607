.col-md-4 {
    width: 40%;
}
.col-md-7 {
    width: 52%;
    overflow:hidden;
}

.profile-card {
    width: 100%;
    z-index: 1;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    position: relative;
}

.profile-card .card{
    height: 600px;
}
.profile-card .card-body {
    position: relative;
    max-height: 250px;
    padding-top:40px;
    border-top-left-radius: 50% 15%;
    border-top-right-radius: 50% 15%;
    margin-top: -80px;
    background: inherit;
    z-index: 999;
}
p#job {
    border-bottom: solid 1px #ccc;
    width: fit-content;
    margin: 0 auto 10px;
    padding: 2px;
}

.profile-img {
    position: absolute;
    top: -65px;
    left: calc(50% - 65px);
}
.profile-img img {
    width: 130px;
    border: solid 5px #fff;
    border-radius: 50%;
}
.social-icons {
    font-size:1.2rem;
}
.social-icons i {
    padding: 0 8px;
    transition:0.4s ease;
    color:initial;
}
.social-icons i:hover {
    color: #f13033;
}
#carouselExampleAutoplaying {
    height: 320px;
}
.carousel-item img{
    width:100%;
}
.card{
    border: none;
    background:#faf6f3;
    border-radius: 0;;
}

.card-body h2{
    margin-top: 40px;
}

/* .mypic{
    position: relative;
}

.mypic img{
    height: 150px;
    width: 150px;
    border-radius: 50px;
    position: absolute;
    top: -100px;
    left: 150px;
    object-fit: cover;

} */

#name {
    margin-top: 40px;
}

#name, #job{
    color: #1c2b24;
}