.hamburger-icon{
    position: relative;
    width:30px;
    height: 20px;
    margin: 20px auto 0;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.hamburger-icon span{
    position: absolute;
    display: block;
    /* width:100%; */
    height: 2px;
    background-color: #1c2b24;
    transition: width 0.3s; /* Add transition property */
}

.hamburger-icon span:nth-child(1){
    width:80%;
    top: 0;
    left: 0;
}

.hamburger-icon span:nth-child(2){
    width:100%;
}

.hamburger-icon span:nth-child(3){
    width:50%;
    bottom: 0;
    left: 0;
}

.hamburger-icon:hover span {
    width: 100%; /* Set width to 100% on hover */
    background-color: #f13033;
  }
/* .hamburger-icon:hover{
    width: 100%;
    background-color: #f13033;
} */
/* .hamburger-icon span:nth-child(3):hover{
    width: 100%;
}  */