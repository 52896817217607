#project-header {
    padding: 0;
}

.project-card{
    position: absolute;
    left: -10px;
    top: 20px;
    z-index:-1;
    border-radius: 4px;
    height:560px;
    /* width: 100%; */
    padding: 30px;
    background-color: #efefef;
    /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5); */
    /* color: #1c2b24; */
}

/* .card-header{
    text-align: start;
    margin: 0;
    border-bottom: 1px solid #ffffff;
    background-color: #efefef;
    color: #1c2b24;
} */

/* #img-card{
    width: 300px;
} */

.img-container{
    width: 300px; 
    height: 250px; 
    overflow: auto;
}

#tsewangs-shop{
    /* width: 100%; Set the desired width */
    /* max-width: 100%; Ensure it doesn't exceed the container's width */
    /* height: 10%; */
    /* object-fit: contain */
    display: block;
    max-width: 100%;
    height: auto;
}