.card-header{
    text-align: start;
    margin: 0;
    border-bottom: 1px solid #ffffff;
    color: #1c2b24;
}

.tt-content h2 b{
    color: #f13033;
}

.list-group li{
    border: none;
    background-color: #efefef;
    color: #1c2b24;
}

.card ul li{
    text-align: start;
    margin: 0 0 0 20px;
}