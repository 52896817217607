@media (min-width: 768px){
.col-md-1 {
    width: 7%;
}
}
.navbar {
    margin: 23px 7px 0;
    height: 550px;
    background-color: #faf6f3;
    text-align: center;
    border-radius: 4px;
}

/* .navbar-logo{
    width:5.2rem;
} */

/* Hamburger menu navbar */
.close-icon:hover {
    color: red;
    cursor: pointer;
    padding: 10px;
    border-radius: 4px;
  }

.hamburger-nav{
    position: fixed;
    top: 0;
    left: 100%;
    width: 15%;
    min-height: 100%;
    display: block;
    z-index: 98;
    background-color: #faf6f3;
    padding-top: 80px;
    transition: 0.4s;
}

.hamburger-nav.is-active{
    left: 0;
}

.hamburger-nav a{
    display: block;
    width: 100%;
    max-width: 200px;
    margin: 0 auto;
    text-align: center;
    padding: 12px 16px;
    color: #1c2b24;
    text-decoration: none;
}

.hamburger-nav a:hover{
    color: #f13033;
}

.hamburger-nav a:before {
    content: none;
}

.nav-item{
    padding: 20px 0;
    font-size: 0.8rem;
    text-transform: uppercase;
    /* letter-spacing: 1.5px; */
    font-weight:500;
}
/** SUBTLE BORDER BOTTOM ON MENU ITEMS **/
#link:before {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 1px;
    background: -moz-radial-gradient(right,ellipse cover,#ddd 0%,rgba(255,255,255,0) 70%);
    background: -webkit-radial-gradient(right,ellipse cover,#ddd 0%,rgba(255,255,255,0) 70%);
    background: radial-gradient(ellipse at right,#ddd 0%,rgba(255,255,255,0) 70%);
}

.collapse{
    margin-top: 100px;
}

#link {
    text-decoration: none;
    color: #1c2b24;
    transition: 0.5s ease;
}

#link:hover, #link:active{
    color: #f13033;
    border-radius: 4px;
    font-weight:bold;
}

.Link:hover{
}

.profile-icon, .resume-icon, .contact-icon, .project-icon {
    font-size: 1.8rem !important;
    margin-bottom: 5px;
}